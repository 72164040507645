import { Eventable } from "@patterns/eventable";
import React from "react";
import { session } from "./session";
import { version } from '../package.json';

export type LiveMessage = {
  action: 'authorize' | 'timed_change' | 'client_change' | 'pong' | 'notify' | 'force-reload' | 'update'
  data: any
}

export enum LiveEvents {
  ClientChange = 'live:client_change',
  Disconnected = 'live:disconnected',
  TimedChange = 'live:timed_change',
  Notification = 'live:notification',
  Pong = 'live:pong',
  Ready = 'live:ready',
  ForceReload = 'live:force_reload',
  Update = 'live:update'
}

class LiveService extends Eventable {
  socket?: WebSocket;
  isReady = false;
  keepAliveInterval: number = -1;
  lastAlive = new Date(0);
  failure = -1
  connectInterval = -1;
  reachabilityHandle = -1;

  connect = async () => {
    const isDev = window.location.host === 'localhost:3000';
    const protocol = isDev ? 'ws' : 'wss';
    const host = isDev ? 'localhost' : window.location.host;
    const suffix = isDev ? ':6080' : '/live';
    const url = `${protocol}://${host}${suffix}`;

    clearInterval(this.connectInterval);
    
    this.socket = new WebSocket(url);
    this.socket.onmessage = this.onMessage;
    this.socket.onopen = this.onOpen;
    this.socket.onclose = this.onClose;
  }

  disconnect = async () => {
    this.socket?.close();
    this.isReady = false;
  }

  keepalive = () => {
    return window.setInterval(() => {
      this.reachabilityHandle = window.setTimeout(() => {
        console.error('[Live Service] => Reachability failure');
        this.notify(LiveEvents.Disconnected);
      }, 29000);
      this.send('ping', { timestamp: new Date().getTime() });
    }, 30000);
  }

  onOpen = (evt: Event) => {
    if (this.connectInterval) {
      clearInterval(this.connectInterval);
    }

    this.authorize()
  }

  onClose = (evt: Event) => {
    this.socket = undefined;
    this.isReady = false;
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
      clearInterval(this.reachabilityHandle);
      this.connectInterval = window.setInterval(() => {
        this.connect()
      }, 10000);
    }
    this.notify(LiveEvents.Disconnected);
  }

  onMessage = (evt: MessageEvent) => {
    this.lastAlive = new Date();
    const data = JSON.parse(evt.data) as LiveMessage;
    // console.log('on message' ,data);
    
    if (data.action === 'authorize') {
      if (data.data.success) {
        this.isReady = true;
        if (this.keepAliveInterval) {
          clearInterval(this.keepAliveInterval);
        }
        this.keepAliveInterval = this.keepalive();
        this.notify(LiveEvents.Ready, data);
      }
    }

    if (data.action === 'timed_change') {
      this.notify(LiveEvents.TimedChange, { action: LiveEvents.TimedChange, data: { date: new Date(data.data.date) }});
    }

    if (data.action === 'client_change') {
      this.notify(LiveEvents.ClientChange, data);
    }

    if (data.action === 'pong') {
      const now = new Date().getTime();
      clearTimeout(this.reachabilityHandle);
      console.log(`[Live Service] => keepalive (${now - data.data.timestamp}ms)`);
      this.notify(LiveEvents.Pong, data);
    }

    if (data.action === 'notify') {
      this.notify(LiveEvents.Notification, data)
    }

    if (data.action === 'update') {
      this.notify(LiveEvents.Update, data.data)
    }

  }

  send = (action: string, data: any) => {
    const message = JSON.stringify({ action, data });

    if (this.isOpen() && this.isReady) {
      this.socket?.send(message);
    }
  }

  private authorize = () => {
    const message = JSON.stringify({
      action: 'authorize',
      data: {
        session: session.id,
        version
      }
    });
    if (this.isOpen()) {
      this.socket?.send(message)
    } else {
      console.log('socket not yet open, retrying in 5 seconds')
      setTimeout(() => {
        this.authorize()
      }, 5000)
    }
  }

  private isOpen = () => {
    return this.socket && this.socket.readyState === 1
  }
}


type LiveContextType = {
  live: LiveService
}

export const live = new LiveService();
export const LiveContext = React.createContext<LiveContextType>({ live });
