import * as React from 'react';
import { List, ListItem } from 'framework7-react';
import { KeyPad } from './keypad';
import EanCoder from './ean_coder';
import { Textfit } from 'react-textfit';

export interface Props {
  item: any
  onCollect: (item: any, value: number) => void
}

export interface State {
  value: number
}

export class NarrowItemView extends React.Component<Props, State> {
  state = {
    value: 0
  } as State

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.item.code !== prevProps.item.code) {
      this.setState({ value: this.props.item.collected || 0 })
    }
  }

  public render() {
    const amount = [this.props.item.rowAmount, this.props.item.unit].filter(f => f).join(' ')
    return (
      <div style={{ flex: 3, borderLeft: '1px solid #d3d3d3', minHeight: 'calc(100vh - 64px)', width: '100%' }}>
        <List mediaList style={{ flex: 3 }} className="item-details narrow">
          <ListItem>
            <Textfit
              mode="single"
              forceSingleModeWidth={false}>
              <strong>{ this.props.item.description }</strong>
            </Textfit>
          </ListItem>
          <ListItem title="Tilattu" after={ amount }/>
          <ListItem title="Hyllypaikka" after={ this.props.item.defaultShelf }/>
          <ListItem>
            <div slot="title">
              Tuotekoodi 
              <strong className="m-l-6">{EanCoder.get(this.props.item.code) || ''}</strong>
            </div>
            <div slot="after">
              <span style={{ fontWeight: 'normal' }}>EAN</span>
              <strong className="m-l-6">{ EanCoder.getEan(this.props.item.code) || '' }</strong>
            </div>
          </ListItem>
          <ListItem title="Varastosaldo" after={ EanCoder.getFreeSaldo(this.props.item.code) || '' }/>
          <KeyPad
            disabled={!this.props.item.code}
            value={this.state.value} 
            onChange={(value) => this.setState({ value })}
            onCollect={() => this.props.onCollect(this.props.item, this.state.value)}
          />
        </List>
      </div>
    );
  }
}
