import moment from 'moment';
import { escapeString } from './common';
import { session } from './session';
import { LemonDatabase } from './settings';

export const formatDate = (date: any) => date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : '';

export function getCurrentUserProductDefaultStockTemplate(code: string) {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
        <tem:GetCurrentUserProductDefaultStock>
          <tem:strSessionId>${session.id}</tem:strSessionId>
          <tem:strProductCode>${code}</tem:strProductCode>
        </tem:GetCurrentUserProductDefaultStock>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function createInventoryTransactionTemplate(code: string, stock: string | number) {
  return `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
      <CreateInventoryTransaction
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns:xsd="http://www.w3.org/2001/XMLSchema"
          xmlns="http://tempuri.org/">
          <strSessionId>${session.id}</strSessionId>
          <strProductCode>${code}</strProductCode>
          <iStock>${stock}</iStock>
      </CreateInventoryTransaction>
    </s:Body>
  </s:Envelope>`
}

export function saveTransactionTemplate(transaction: any) {
  return `<soapenv:Envelope 
    xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
    xmlns:tem="http://tempuri.org/"
    xmlns:lem="http://schemas.datacontract.org/2004/07/Lemonsoft.LemonsoftServiceLibrary.Proxy"
    xmlns:a="http://schemas.datacontract.org/2004/07/Lemonsoft.LemonsoftServiceLibrary.Proxy">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:SaveTransaction>
          <tem:strSessionId>${session.id}</tem:strSessionId>
          ${transaction}
      </tem:SaveTransaction>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function lemonLogin(username: string, password: string) {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/" xmlns:lem="http://schemas.datacontract.org/2004/07/Lemonsoft.LemonsoftServiceLibrary.User">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:LogIn>
        <tem:oLogInInfo>
          <lem:CompanyDatabase>${LemonDatabase}</lem:CompanyDatabase>
          <lem:Password>${password}</lem:Password>
          <lem:UserName>${username}</lem:UserName>
        </tem:oLogInInfo>
      </tem:LogIn>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function lockItemTemplate(order_id: string) {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:LockSmith_LockItem>
          <tem:strSessionId>${session.id}</tem:strSessionId>
          <tem:iApplicationID>8</tem:iApplicationID>
          <tem:iObjectID>${ order_id }</tem:iObjectID>
      </tem:LockSmith_LockItem>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function releaseItemTemplate(order_id: string) {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:LockSmith_ReleaseItem>
          <tem:strSessionId>${session.id}</tem:strSessionId>
          <tem:iApplicationID>8</tem:iApplicationID>
          <tem:iObjectID>${ order_id }</tem:iObjectID>
      </tem:LockSmith_ReleaseItem>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function keepAliveTemplate() {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:IsAlive>
        <tem:strSessionID>${ session.id }</tem:strSessionID>
      </tem:IsAlive>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function getCustomerTemplate(customerNumber: string)  {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/> 
    <soapenv:Body>
      <tem:GetCustomer>
        <tem:strSessionID>${ session.id }</tem:strSessionID>
        <tem:iCustomer>${ customerNumber }</tem:iCustomer>
      </tem:GetCustomer>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function getProductTemplate(code: string)  {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Body>
      <tem:GetProduct>
        <tem:strSessionId>${ session.id }</tem:strSessionId>
        <tem:strProductCode>${ code }</tem:strProductCode>
      </tem:GetProduct>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function getSellersTemplate() {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/" xmlns:lem="http://schemas.datacontract.org/2004/07/Lemonsoft.LemonsoftServiceLibrary.User">
    <soapenv:Header/> 
    <soapenv:Body>
      <tem:GetPersonsWithRole>
        <tem:strSessionId>${ session.id }</tem:strSessionId>
        <tem:iRole>4</tem:iRole>
      </tem:GetPersonsWithRole>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function getOrderTemplate(id: string) {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:GetSalesOrderReadyToCollect>
        <tem:strSessionId>${ session.id }</tem:strSessionId>
        <tem:iNumber>${ id }</tem:iNumber>
      </tem:GetSalesOrderReadyToCollect>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function getOrdersTemplate() {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:GetSalesOrderReadyToCollect>
        <tem:strSessionId>${ session.id }</tem:strSessionId>
      </tem:GetSalesOrderReadyToCollect>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export function getShelfOrderTemplate(salesOrderNumber: string) {
  return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tem:GetSalesOrderShelfOrder>
        <tem:strSessionId>${ session.id }</tem:strSessionId>
        <tem:iNumber>${salesOrderNumber}</tem:iNumber>
      </tem:GetSalesOrderShelfOrder>
    </soapenv:Body>
  </soapenv:Envelope>`
}

export const rowToXML = (row: any) => `<a:SalesOrderRow>
  <a:Accountlist_account>${ row['a:Accountlist_account'][0] }</a:Accountlist_account>
  <a:Codelist_costcenter>${ row['a:Codelist_costcenter'][0] }</a:Codelist_costcenter>
  <a:Codelist_project_code>${ row['a:Codelist_project_code'][0] }</a:Codelist_project_code>
  <a:Codelist_worknumber>${ row['a:Codelist_worknumber'][0] }</a:Codelist_worknumber>
  <a:Product_stock>${ row['a:Product_stock'][0] }</a:Product_stock>
  <a:Project_number>${ row['a:Project_number'][0] }</a:Project_number>
  <a:Project_phase_id>${ row['a:Project_phase_id'][0] }</a:Project_phase_id>
  <a:Row_Discount>${ row['a:Row_Discount'][0] }</a:Row_Discount>
  <a:Row_Per>${ row['a:Row_Per'][0] }</a:Row_Per>
  <a:Row_Salesprice_str>${ row['a:Row_Salesprice_str'][0] }</a:Row_Salesprice_str>
  <a:Row_Total>${ row['a:Row_Total'][0] }</a:Row_Total>
  <a:Row_Unitprice>${ row['a:Row_Unitprice'][0] }</a:Row_Unitprice>
  <a:Row_amount>${ row['a:Row_amount'][0] }</a:Row_amount>
  <a:Row_amount2>${ row['a:Row_amount2'][0] }</a:Row_amount2>
  <a:Row_color>${ row['a:Row_color'][0] }</a:Row_color>
  <a:Row_customer_percent>${ row['a:Row_customer_percent'][0] }</a:Row_customer_percent>
  <a:Row_dimension>${ row['a:Row_dimension'][0] }</a:Row_dimension>
  <a:Row_id>${ row['a:Row_id'][0] }</a:Row_id>
  <a:Row_innernote>${ row['a:Row_innernote'][0] }</a:Row_innernote>
  <a:Row_lta>${ row['a:Row_lta'][0] }</a:Row_lta>
  <a:Row_net>${ row['a:Row_net'][0] }</a:Row_net>
  <a:Row_no_stock_update>${ row['a:Row_no_stock_update'][0] }</a:Row_no_stock_update>
  <a:Row_note>${ row['a:Row_note'][0] }</a:Row_note>
  <a:Row_number>${ row['a:Row_number'][0] }</a:Row_number>
  <a:Row_position>${ row['a:Row_position'][0] }</a:Row_position>
  <a:Row_productcode>${ row['a:Row_productcode'][0] }</a:Row_productcode>
  <a:Row_productdescription>${ escapeString(row['a:Row_productdescription'][0]).replace('&amp;', '&').replace('&', '&amp;') }</a:Row_productdescription>
  <a:Row_productdescription2>${ escapeString(row['a:Row_productdescription2'][0]).replace('&lt;', '<').replace('<', '&lt;') }</a:Row_productdescription2>
  <a:Row_stock_unit>${ row['a:Row_stock_unit'][0] }</a:Row_stock_unit>
  <a:Row_taxamount>${ row['a:Row_taxamount'][0] }</a:Row_taxamount>
  <a:Row_taxrate>${ row['a:Row_taxrate'][0] }</a:Row_taxrate>
  <a:Row_type>${ row['a:Row_type'][0] }</a:Row_type>
  <a:Row_unit>${ row['a:Row_unit'][0] }</a:Row_unit>
  <a:Row_unit_netprice>${ row['a:Row_unit_netprice'][0] }</a:Row_unit_netprice>
  <a:Row_unit_netprice_curr>${ row['a:Row_unit_netprice_curr'][0] }</a:Row_unit_netprice_curr>
  <a:Row_weight>${ row['a:Row_weight'][0] }</a:Row_weight>
  <a:Row_original_amount>${ row['a:Row_original_amount'][0] }</a:Row_original_amount>
  <a:Sales_orderrow_delivery_date>${ row['a:Sales_orderrow_delivery_date'][0] }</a:Sales_orderrow_delivery_date>
  <a:BatchNumbers>${ row['a:BatchNumbers'][0] }</a:BatchNumbers>
  <a:Box>${ row['a:Box'][0] }</a:Box>
  <a:DefaultShelf>${ row['a:DefaultShelf'][0] }</a:DefaultShelf>
  <a:DefaultShelfCollectOrder>${ row['a:DefaultShelfCollectOrder'][0] }</a:DefaultShelfCollectOrder>
  <a:Delivered>${ row['a:Delivered'][0] }</a:Delivered>
  <a:DoDelivery0Amount>${ row['a:DoDelivery0Amount'][0] }</a:DoDelivery0Amount>
  <a:JT>${ row['a:JT'][0] }</a:JT>
  <a:OrderNumber>${ row['a:OrderNumber'][0] }</a:OrderNumber>
  <a:Person_seller_number>${ row['a:Person_seller_number'][0] }</a:Person_seller_number>
  <a:SalesOrderRowSizes>${ row['a:SalesOrderRowSizes'][0] }</a:SalesOrderRowSizes>
  <a:Sales_orderrow_collected_amount>${ row['a:Sales_orderrow_collected_amount'][0] }</a:Sales_orderrow_collected_amount>
  <a:Sales_orderrow_delivery_text>${ row['a:Sales_orderrow_delivery_text'][0] }</a:Sales_orderrow_delivery_text>
  <a:Sales_orderrow_person_number>${ row['a:Sales_orderrow_person_number'][0] }</a:Sales_orderrow_person_number>
  <a:Sales_orderrow_prev_delivery>${ row['a:Sales_orderrow_prev_delivery'][0] }</a:Sales_orderrow_prev_delivery>
  <a:Sales_orderrow_reference>${ row['a:Sales_orderrow_reference'][0] }</a:Sales_orderrow_reference>
  <a:Sales_orderrow_shelf>${ row['a:Sales_orderrow_shelf'][0] }</a:Sales_orderrow_shelf>
  <a:Sales_orderrow_state>${ row['a:Sales_orderrow_state'][0] }</a:Sales_orderrow_state>
  <a:Sales_orderrow_total_delivery>${ row['a:Sales_orderrow_total_delivery'][0] }</a:Sales_orderrow_total_delivery>
  <a:Sales_orderrow_volyme>${ row['a:Sales_orderrow_volyme'][0] }</a:Sales_orderrow_volyme>
  <a:Serials>${ row['a:Serials'][0] }</a:Serials>
  <a:ShelfList>
    <a:StockTransactionShelf>
      <a:Shelf_amount>${ row['a:Delivered'][0] }</a:Shelf_amount>
      <a:Stock_shelf>${ row['a:DefaultShelf'][0] }</a:Stock_shelf>
    </a:StockTransactionShelf>
  </a:ShelfList>
  <a:ToBeDelivered>${ row['a:ToBeDelivered'][0] }</a:ToBeDelivered>    
</a:SalesOrderRow>`

export const salesOrderMarkCollected = (order: any) => `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <SalesOrderMarkCollected xmlns="http://tempuri.org/">
      <strSessionId>${ session.id }</strSessionId>
      <oProxySalesOrder xmlns="http://tempuri.org/" xmlns:a="http://schemas.datacontract.org/2004/07/Lemonsoft.LemonsoftServiceLibrary.Proxy" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <a:Codelist_driver>${ order['a:Codelist_driver'][0] }</a:Codelist_driver>
        <a:Sales_order_date>${ order['a:Sales_order_date'][0] }</a:Sales_order_date>
        <a:Sales_order_delivery_customer_address3>${ order['a:Sales_order_delivery_customer_address3'][0] }</a:Sales_order_delivery_customer_address3>
        <a:Sales_order_delivery_customer_name1>${ order['a:Sales_order_delivery_customer_name1'][0] }</a:Sales_order_delivery_customer_name1>
        <a:Sales_order_delivery_customer_number>${ order['a:Sales_order_delivery_customer_number'][0] }</a:Sales_order_delivery_customer_number>
        <a:Sales_order_delivery_date>${ order['a:Sales_order_delivery_date'][0] }</a:Sales_order_delivery_date>
        <a:Sales_order_delivery_text>${ order['a:Sales_order_delivery_text'][0] }</a:Sales_order_delivery_text>
        <a:Sales_order_description>${ order['a:Sales_order_description'][0] }</a:Sales_order_description>
        <a:Sales_order_id>${ order['a:Sales_order_id'][0] }</a:Sales_order_id>
        <a:Sales_order_number>${ order['a:Sales_order_number'][0] }</a:Sales_order_number>
        <a:Sales_order_ordermark>${ order['a:Sales_order_ordermark'][0] }</a:Sales_order_ordermark>
        <a:Sales_order_state>${ order['a:Sales_order_state'][0] }</a:Sales_order_state>
        <a:Sales_order_totalsum>${ order['a:Sales_order_totalsum'][0] }</a:Sales_order_totalsum>
        <a:Agreement_response_time>${ order['a:Agreement_response_time'][0] }</a:Agreement_response_time>
        <a:Campaign_id>${ order['a:Campaign_id'][0] }</a:Campaign_id>
        <a:Company_location_id>${ order['a:Company_location_id'][0] }</a:Company_location_id>
        <a:Currency_code>${ order['a:Currency_code'][0] }</a:Currency_code>
        <a:Delivery_method>${ order['a:Delivery_method'][0] }</a:Delivery_method>
        <a:Deliveryinfo_id>${ order['a:Deliveryinfo_id'][0] }</a:Deliveryinfo_id>
        <a:Language_code>${ order['a:Language_code'][0] }</a:Language_code>
        <a:Mark_code>${ order['a:Mark_code'][0] }</a:Mark_code>
        <a:OrderRows>
          ${ order['a:OrderRows'][0]['a:SalesOrderRow'].map((row: any) => rowToXML(row)).join('')}
        </a:OrderRows>
        <a:Payment_term>${ order['a:Payment_term'][0] }</a:Payment_term>
        <a:Person_invoice_res_person>${ order['a:Person_invoice_res_person'][0] }</a:Person_invoice_res_person>
        <a:Person_seller_number>${ order['a:Person_seller_number'][0] }</a:Person_seller_number>
        <a:RowCount>${ order['a:RowCount'][0] }</a:RowCount>
        <a:Sales_order_confirmed_bit>${ order['a:Sales_order_confirmed_bit'][0] }</a:Sales_order_confirmed_bit>
        <a:Sales_order_consignee_customer_address1>${ order['a:Sales_order_consignee_customer_address1'][0] }</a:Sales_order_consignee_customer_address1>
        <a:Sales_order_consignee_customer_address2>${ order['a:Sales_order_consignee_customer_address2'][0] }</a:Sales_order_consignee_customer_address2>
        <a:Sales_order_consignee_customer_address3>${ order['a:Sales_order_consignee_customer_address3'][0] }</a:Sales_order_consignee_customer_address3>
        <a:Sales_order_consignee_customer_contact>${ order['a:Sales_order_consignee_customer_contact'][0] }</a:Sales_order_consignee_customer_contact>
        <a:Sales_order_consignee_customer_contact_email>${ order['a:Sales_order_consignee_customer_contact_email'][0] }</a:Sales_order_consignee_customer_contact_email>
        <a:Sales_order_consignee_customer_contact_phone>${ order['a:Sales_order_consignee_customer_contact_phone'][0] }</a:Sales_order_consignee_customer_contact_phone>
        <a:Sales_order_consignee_customer_country>${ order['a:Sales_order_consignee_customer_country'][0] }</a:Sales_order_consignee_customer_country>
        <a:Sales_order_consignee_customer_name1>${ order['a:Sales_order_consignee_customer_name1'][0] }</a:Sales_order_consignee_customer_name1>
        <a:Sales_order_consignee_customer_name2>${ order['a:Sales_order_consignee_customer_name2'][0] }</a:Sales_order_consignee_customer_name2>
        <a:Sales_order_consignee_customer_number>${ order['a:Sales_order_consignee_customer_number'][0] }</a:Sales_order_consignee_customer_number>
        <a:Sales_order_currency_rate>${ order['a:Sales_order_currency_rate'][0] }</a:Sales_order_currency_rate>
        <a:Sales_order_customer_address1>${ order['a:Sales_order_customer_address1'][0] }</a:Sales_order_customer_address1>
        <a:Sales_order_customer_address2>${ order['a:Sales_order_customer_address2'][0] }</a:Sales_order_customer_address2>
        <a:Sales_order_customer_address3>${ order['a:Sales_order_customer_address3'][0] }</a:Sales_order_customer_address3>
        <a:Sales_order_customer_contact>${ order['a:Sales_order_customer_contact'][0] }</a:Sales_order_customer_contact>
        <a:Sales_order_customer_contact_email>${ order['a:Sales_order_customer_contact_email'][0] }</a:Sales_order_customer_contact_email>
        <a:Sales_order_customer_contact_phone>${ order['a:Sales_order_customer_contact_phone'][0] }</a:Sales_order_customer_contact_phone>
        <a:Sales_order_customer_country>${ order['a:Sales_order_customer_country'][0] }</a:Sales_order_customer_country>
        <a:Sales_order_customer_name1>${ order['a:Sales_order_customer_name1'][0] }</a:Sales_order_customer_name1>
        <a:Sales_order_customer_name2>${ order['a:Sales_order_customer_name2'][0] }</a:Sales_order_customer_name2>
        <a:Sales_order_customer_number>${ order['a:Sales_order_customer_number'][0] }</a:Sales_order_customer_number>
        <a:Sales_order_customer_ordernumber>${ order['a:Sales_order_customer_ordernumber'][0] }</a:Sales_order_customer_ordernumber>
        <a:Sales_order_customer_reference>${ order['a:Sales_order_customer_reference'][0] }</a:Sales_order_customer_reference>
        <a:Sales_order_delivery_code>${ order['a:Sales_order_delivery_code'][0] }</a:Sales_order_delivery_code>
        <a:Sales_order_delivery_customer_address1>${ order['a:Sales_order_delivery_customer_address1'][0] }</a:Sales_order_delivery_customer_address1>
        <a:Sales_order_delivery_customer_address2>${ order['a:Sales_order_delivery_customer_address2'][0] }</a:Sales_order_delivery_customer_address2>
        <a:Sales_order_delivery_customer_contact>${ order['a:Sales_order_delivery_customer_contact'][0] }</a:Sales_order_delivery_customer_contact>
        <a:Sales_order_delivery_customer_contact_email>${ order['a:Sales_order_delivery_customer_contact_email'][0] }</a:Sales_order_delivery_customer_contact_email>
        <a:Sales_order_delivery_customer_contact_phone>${ order['a:Sales_order_delivery_customer_contact_phone'][0] }</a:Sales_order_delivery_customer_contact_phone>
        <a:Sales_order_delivery_customer_country>${ order['a:Sales_order_delivery_customer_country'][0] }</a:Sales_order_delivery_customer_country>
        <a:Sales_order_delivery_customer_name2>${ order['a:Sales_order_delivery_customer_name2'][0] }</a:Sales_order_delivery_customer_name2>
        <a:Sales_order_delivery_term>${ order['a:Sales_order_delivery_term'][0] }</a:Sales_order_delivery_term>
        <a:Sales_order_invoice_reference>${ order['a:Sales_order_invoice_reference'][0] }</a:Sales_order_invoice_reference>
        <a:Sales_order_material_bit>${ order['a:Sales_order_material_bit'][0] }</a:Sales_order_material_bit>
        <a:Sales_order_note>${ order['a:Sales_order_note'][0] }</a:Sales_order_note>
        <a:Sales_order_orderer_customer_address1>${ order['a:Sales_order_orderer_customer_address1'][0] }</a:Sales_order_orderer_customer_address1>
        <a:Sales_order_orderer_customer_address2>${ order['a:Sales_order_orderer_customer_address2'][0] }</a:Sales_order_orderer_customer_address2>
        <a:Sales_order_orderer_customer_address3>${ order['a:Sales_order_orderer_customer_address3'][0] }</a:Sales_order_orderer_customer_address3>
        <a:Sales_order_orderer_customer_contact>${ order['a:Sales_order_orderer_customer_contact'][0] }</a:Sales_order_orderer_customer_contact>
        <a:Sales_order_orderer_customer_contact_email>${ order['a:Sales_order_orderer_customer_contact_email'][0] }</a:Sales_order_orderer_customer_contact_email>
        <a:Sales_order_orderer_customer_contact_phone>${ order['a:Sales_order_orderer_customer_contact_phone'][0] }</a:Sales_order_orderer_customer_contact_phone>
        <a:Sales_order_orderer_customer_country>${ order['a:Sales_order_orderer_customer_country'][0] }</a:Sales_order_orderer_customer_country>
        <a:Sales_order_orderer_customer_name1>${ order['a:Sales_order_orderer_customer_name1'][0] }</a:Sales_order_orderer_customer_name1>
        <a:Sales_order_orderer_customer_name2>${ order['a:Sales_order_orderer_customer_name2'][0] }</a:Sales_order_orderer_customer_name2>
        <a:Sales_order_orderer_customer_number>${ order['a:Sales_order_orderer_customer_number'][0] }</a:Sales_order_orderer_customer_number>
        <a:Sales_order_our_reference>${ order['a:Sales_order_our_reference'][0] }</a:Sales_order_our_reference>
        <a:Sales_order_packlist_bit>${ order['a:Sales_order_packlist_bit'][0] }</a:Sales_order_packlist_bit>
        <a:Sales_order_preinvoice_percent>${ order['a:Sales_order_preinvoice_percent'][0] }</a:Sales_order_preinvoice_percent>
        <a:Sales_order_project_number>${ order['a:Sales_order_project_number'][0] }</a:Sales_order_project_number>
        <a:Sales_order_taxtype_bit>${ order['a:Sales_order_taxtype_bit'][0] }</a:Sales_order_taxtype_bit>
        <a:Sales_order_type>${ order['a:Sales_order_type'][0] }</a:Sales_order_type>
        <a:Weight>${ order['a:Weight'][0] }</a:Weight>
      </oProxySalesOrder>
    </SalesOrderMarkCollected>
  </s:Body>
</s:Envelope>`

export const saveSalesOrder = (order: any) => `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <SaveSalesOrder xmlns="http://tempuri.org/">
      <strSessionId>${ session.id }</strSessionId>
      <oSalesOrder xmlns="http://tempuri.org/" xmlns:a="http://schemas.datacontract.org/2004/07/Lemonsoft.LemonsoftServiceLibrary.Proxy" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <a:Codelist_driver>${ order['a:Codelist_driver'][0] }</a:Codelist_driver>
        <a:Sales_order_date>${ order['a:Sales_order_date'][0] }</a:Sales_order_date>
        <a:Sales_order_delivery_customer_address3>${ order['a:Sales_order_delivery_customer_address3'][0] }</a:Sales_order_delivery_customer_address3>
        <a:Sales_order_delivery_customer_name1>${ order['a:Sales_order_delivery_customer_name1'][0] }</a:Sales_order_delivery_customer_name1>
        <a:Sales_order_delivery_customer_number>${ order['a:Sales_order_delivery_customer_number'][0] }</a:Sales_order_delivery_customer_number>
        <a:Sales_order_delivery_date>${ order['a:Sales_order_delivery_date'][0] }</a:Sales_order_delivery_date>
        <a:Sales_order_delivery_text>${ order['a:Sales_order_delivery_text'][0] }</a:Sales_order_delivery_text>
        <a:Sales_order_description>${ order['a:Sales_order_description'][0] }</a:Sales_order_description>
        <a:Sales_order_id>${ order['a:Sales_order_id'][0] }</a:Sales_order_id>
        <a:Sales_order_number>${ order['a:Sales_order_number'][0] }</a:Sales_order_number>
        <a:Sales_order_ordermark>${ order['a:Sales_order_ordermark'][0] }</a:Sales_order_ordermark>
        <a:Sales_order_state>${ order['a:Sales_order_state'][0] }</a:Sales_order_state>
        <a:Sales_order_totalsum>${ order['a:Sales_order_totalsum'][0] }</a:Sales_order_totalsum>
        <a:Agreement_response_time>${ order['a:Agreement_response_time'][0] }</a:Agreement_response_time>
        <a:Campaign_id>${ order['a:Campaign_id'][0] }</a:Campaign_id>
        <a:Company_location_id>${ order['a:Company_location_id'][0] }</a:Company_location_id>
        <a:Currency_code>${ order['a:Currency_code'][0] }</a:Currency_code>
        <a:Delivery_method>${ order['a:Delivery_method'][0] }</a:Delivery_method>
        <a:Deliveryinfo_id>${ order['a:Deliveryinfo_id'][0] }</a:Deliveryinfo_id>
        <a:Language_code>${ order['a:Language_code'][0] }</a:Language_code>
        <a:Mark_code>${ order['a:Mark_code'][0] }</a:Mark_code>
        <a:OrderRows>
          ${ order['a:OrderRows'][0]['a:SalesOrderRow'].map((row: any) => rowToXML(row)).join('')}
        </a:OrderRows>
        <a:Payment_term>${ order['a:Payment_term'][0] }</a:Payment_term>
        <a:Person_invoice_res_person>${ order['a:Person_invoice_res_person'][0] }</a:Person_invoice_res_person>
        <a:Person_seller_number>${ order['a:Person_seller_number'][0] }</a:Person_seller_number>
        <a:RowCount>${ order['a:RowCount'][0] }</a:RowCount>
        <a:Sales_order_confirmed_bit>${ order['a:Sales_order_confirmed_bit'][0] }</a:Sales_order_confirmed_bit>
        <a:Sales_order_consignee_customer_address1>${ order['a:Sales_order_consignee_customer_address1'][0] }</a:Sales_order_consignee_customer_address1>
        <a:Sales_order_consignee_customer_address2>${ order['a:Sales_order_consignee_customer_address2'][0] }</a:Sales_order_consignee_customer_address2>
        <a:Sales_order_consignee_customer_address3>${ order['a:Sales_order_consignee_customer_address3'][0] }</a:Sales_order_consignee_customer_address3>
        <a:Sales_order_consignee_customer_contact>${ order['a:Sales_order_consignee_customer_contact'][0] }</a:Sales_order_consignee_customer_contact>
        <a:Sales_order_consignee_customer_contact_email>${ order['a:Sales_order_consignee_customer_contact_email'][0] }</a:Sales_order_consignee_customer_contact_email>
        <a:Sales_order_consignee_customer_contact_phone>${ order['a:Sales_order_consignee_customer_contact_phone'][0] }</a:Sales_order_consignee_customer_contact_phone>
        <a:Sales_order_consignee_customer_country>${ order['a:Sales_order_consignee_customer_country'][0] }</a:Sales_order_consignee_customer_country>
        <a:Sales_order_consignee_customer_name1>${ order['a:Sales_order_consignee_customer_name1'][0] }</a:Sales_order_consignee_customer_name1>
        <a:Sales_order_consignee_customer_name2>${ order['a:Sales_order_consignee_customer_name2'][0] }</a:Sales_order_consignee_customer_name2>
        <a:Sales_order_consignee_customer_number>${ order['a:Sales_order_consignee_customer_number'][0] }</a:Sales_order_consignee_customer_number>
        <a:Sales_order_currency_rate>${ order['a:Sales_order_currency_rate'][0] }</a:Sales_order_currency_rate>
        <a:Sales_order_customer_address1>${ order['a:Sales_order_customer_address1'][0] }</a:Sales_order_customer_address1>
        <a:Sales_order_customer_address2>${ order['a:Sales_order_customer_address2'][0] }</a:Sales_order_customer_address2>
        <a:Sales_order_customer_address3>${ order['a:Sales_order_customer_address3'][0] }</a:Sales_order_customer_address3>
        <a:Sales_order_customer_contact>${ order['a:Sales_order_customer_contact'][0] }</a:Sales_order_customer_contact>
        <a:Sales_order_customer_contact_email>${ order['a:Sales_order_customer_contact_email'][0] }</a:Sales_order_customer_contact_email>
        <a:Sales_order_customer_contact_phone>${ order['a:Sales_order_customer_contact_phone'][0] }</a:Sales_order_customer_contact_phone>
        <a:Sales_order_customer_country>${ order['a:Sales_order_customer_country'][0] }</a:Sales_order_customer_country>
        <a:Sales_order_customer_name1>${ order['a:Sales_order_customer_name1'][0] }</a:Sales_order_customer_name1>
        <a:Sales_order_customer_name2>${ order['a:Sales_order_customer_name2'][0] }</a:Sales_order_customer_name2>
        <a:Sales_order_customer_number>${ order['a:Sales_order_customer_number'][0] }</a:Sales_order_customer_number>
        <a:Sales_order_customer_ordernumber>${ order['a:Sales_order_customer_ordernumber'][0] }</a:Sales_order_customer_ordernumber>
        <a:Sales_order_customer_reference>${ order['a:Sales_order_customer_reference'][0] }</a:Sales_order_customer_reference>
        <a:Sales_order_delivery_code>${ order['a:Sales_order_delivery_code'][0] }</a:Sales_order_delivery_code>
        <a:Sales_order_delivery_customer_address1>${ order['a:Sales_order_delivery_customer_address1'][0] }</a:Sales_order_delivery_customer_address1>
        <a:Sales_order_delivery_customer_address2>${ order['a:Sales_order_delivery_customer_address2'][0] }</a:Sales_order_delivery_customer_address2>
        <a:Sales_order_delivery_customer_contact>${ order['a:Sales_order_delivery_customer_contact'][0] }</a:Sales_order_delivery_customer_contact>
        <a:Sales_order_delivery_customer_contact_email>${ order['a:Sales_order_delivery_customer_contact_email'][0] }</a:Sales_order_delivery_customer_contact_email>
        <a:Sales_order_delivery_customer_contact_phone>${ order['a:Sales_order_delivery_customer_contact_phone'][0] }</a:Sales_order_delivery_customer_contact_phone>
        <a:Sales_order_delivery_customer_country>${ order['a:Sales_order_delivery_customer_country'][0] }</a:Sales_order_delivery_customer_country>
        <a:Sales_order_delivery_customer_name2>${ order['a:Sales_order_delivery_customer_name2'][0] }</a:Sales_order_delivery_customer_name2>
        <a:Sales_order_delivery_term>${ order['a:Sales_order_delivery_term'][0] }</a:Sales_order_delivery_term>
        <a:Sales_order_invoice_reference>${ order['a:Sales_order_invoice_reference'][0] }</a:Sales_order_invoice_reference>
        <a:Sales_order_material_bit>${ order['a:Sales_order_material_bit'][0] }</a:Sales_order_material_bit>
        <a:Sales_order_note>${ order['a:Sales_order_note'][0] }</a:Sales_order_note>
        <a:Sales_order_orderer_customer_address1>${ order['a:Sales_order_orderer_customer_address1'][0] }</a:Sales_order_orderer_customer_address1>
        <a:Sales_order_orderer_customer_address2>${ order['a:Sales_order_orderer_customer_address2'][0] }</a:Sales_order_orderer_customer_address2>
        <a:Sales_order_orderer_customer_address3>${ order['a:Sales_order_orderer_customer_address3'][0] }</a:Sales_order_orderer_customer_address3>
        <a:Sales_order_orderer_customer_contact>${ order['a:Sales_order_orderer_customer_contact'][0] }</a:Sales_order_orderer_customer_contact>
        <a:Sales_order_orderer_customer_contact_email>${ order['a:Sales_order_orderer_customer_contact_email'][0] }</a:Sales_order_orderer_customer_contact_email>
        <a:Sales_order_orderer_customer_contact_phone>${ order['a:Sales_order_orderer_customer_contact_phone'][0] }</a:Sales_order_orderer_customer_contact_phone>
        <a:Sales_order_orderer_customer_country>${ order['a:Sales_order_orderer_customer_country'][0] }</a:Sales_order_orderer_customer_country>
        <a:Sales_order_orderer_customer_name1>${ order['a:Sales_order_orderer_customer_name1'][0] }</a:Sales_order_orderer_customer_name1>
        <a:Sales_order_orderer_customer_name2>${ order['a:Sales_order_orderer_customer_name2'][0] }</a:Sales_order_orderer_customer_name2>
        <a:Sales_order_orderer_customer_number>${ order['a:Sales_order_orderer_customer_number'][0] }</a:Sales_order_orderer_customer_number>
        <a:Sales_order_our_reference>${ order['a:Sales_order_our_reference'][0] }</a:Sales_order_our_reference>
        <a:Sales_order_packlist_bit>${ order['a:Sales_order_packlist_bit'][0] }</a:Sales_order_packlist_bit>
        <a:Sales_order_preinvoice_percent>${ order['a:Sales_order_preinvoice_percent'][0] }</a:Sales_order_preinvoice_percent>
        <a:Sales_order_project_number>${ order['a:Sales_order_project_number'][0] }</a:Sales_order_project_number>
        <a:Sales_order_taxtype_bit>${ order['a:Sales_order_taxtype_bit'][0] }</a:Sales_order_taxtype_bit>
        <a:Sales_order_type>${ order['a:Sales_order_type'][0] }</a:Sales_order_type>
        <a:Weight>${ order['a:Weight'][0] }</a:Weight>
      </oSalesOrder>
    </SaveSalesOrder>
  </s:Body>
</s:Envelope>`