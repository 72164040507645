import React from 'react';
import { View, F7App } from 'framework7-react';
import About from './about';
import Login from './login';
import Orders from './orders';
import Detail from './detail';
import KeyCaptureInstance from './key_capture';
import EanCoder from './ean_coder';
import { session } from './session';
import LogRocket from 'logrocket';
import './App.css';
import { Dashboard } from './dashboard';
import { intercept } from './repository';
import { live } from './live.service';
import { NarrowDetail } from './narrow_detail';
import { ProductPage } from './product';
import { InventoryPage } from './inventory';

const f7params = {
  name: 'Warehouse',
  id: 'fi.tm-tieto.Warehouse',
  routes: [
    {
      path: '/',
      component: Dashboard,
    },
    {
      path: '/orders',
      component: Orders,
    },
    {
      path: '/product',
      component: ProductPage,
    },
    {
      path: '/inventory',
      component: InventoryPage,
    },
    {
      path: '/detail/:id',
      component: Detail,
    },
    {
      path: '/narrow_detail/:id',
      component: NarrowDetail,
    },
    {
      path: '/about/',
      component: About,
    },
    {
      path: '/login/',
      component: Login,
    },
  ]
}

export interface Props {}

export interface State {
  loggedIn: false
}

export default class App extends React.Component<Props> {
  componentDidMount() {
    intercept();
    document.body.onkeypress = KeyCaptureInstance.capture as any;
    EanCoder.fetch();
    session.observe(this.onSessionChange);
    session.restore();
    (window as any).progressBar = this.$f7?.progressbar;
    LogRocket.init('1zlzti/varasto');
  }

  componentWillUnmount() {
    session.cancel(this.onSessionChange)
  }

  private onSessionChange = async () => {
    if (session.authorized && !live.isReady) {
      await live.connect();
      (window as any).live = live;
    }
    this.setState({ loggedIn: session.authorized })
  }

  public render() {
    if (session.authorized) {
      return (
        <F7App params={f7params}>
          <View main />
        </F7App>
      );
    }

    return <F7App params={f7params}>
      <Login />
    </F7App>
  }
}
