import { Eventable } from "./eventable";

export enum OrderManagerEvents {
  Changed = 'order-manager:changed'
}

class OrderManager extends Eventable {
  private collected: any[] = [];
  private date: string;

  constructor() {
    super();
    const today = new Date().toDateString();
    this.date = localStorage.getItem('date') || new Date().toDateString();

    if (this.date === today) {
      this.collected = JSON.parse(localStorage.getItem('collected') || '[]');
    } else {
      this.reset()
    }
  }

  public getCollected = () => {
    const today = new Date().toDateString();
    if (this.date !== today) {
      this.reset()
    }

    return this.collected.sort((a: any, b: any) => b.timestamp - a.timestamp)
  }

  public addOrder = (order: any) => {
    order.timestamp = new Date().getTime();
    const existingIdx = this.collected.findIndex(i => i[['a:Sales_order_number'][0]] === order[['a:Sales_order_number'][0]]);
    if (existingIdx >= 0) {
      this.collected.splice(existingIdx, 1, order)
    } else {
      this.collected.push(order);
    }
    this.save();
    this.notify(OrderManagerEvents.Changed)
  }

  private save = () => {
    localStorage.setItem('date', this.date);
    localStorage.setItem('collected', JSON.stringify(this.collected));
  }

  private reset = () => {
    this.collected = [];
    this.date = new Date().toDateString();
    localStorage.removeItem('collected');
    this.save();
  }
}

export const orderManager = new OrderManager()