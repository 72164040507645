import { Link, Navbar, NavLeft, NavRight, Page } from 'framework7-react';
import * as React from 'react';
import { FlexRow } from './common';
import logo from './logo.png';
import { session } from './session';

export interface Props {
}

export class Dashboard extends React.Component<Props> {
  private logout() {
    session.logout();
  }
  public render() {
    const isNarrow = window.innerWidth < 770;

    return (
      <Page noSwipeback>
        <Navbar>
          <NavLeft>
            <img alt="logo" src={logo} style={{ maxHeight: 34, marginLeft: 24 }}/>
          </NavLeft>
          { !isNarrow && <div className="f-1">Päänäkymä</div> }
          <NavRight>
            <Link icon="icon-logout" onClick={this.logout}>Kirjaudu ulos</Link>
          </NavRight>
        </Navbar>
        <FlexRow>
          <div className="dashboard-box">
            <Link icon="icon-bars" onClick={() => (this as any).$f7router.navigate('/orders')}>
              Hyllykeräily
            </Link>
          </div>
          <div className="dashboard-box">
            <Link icon="icon-bars" onClick={() => (this as any).$f7router.navigate('/product')}>
              Tuotehaku
            </Link>
          </div>
        </FlexRow>
        <FlexRow>
          <div className="dashboard-box">
            <Link icon="icon-bars" onClick={() => (this as any).$f7router.navigate('/inventory')}>
              Inventaario (EI KÄYTETTÄVISSÄ)
            </Link>
          </div>
          <div className="dashboard-box disabled">Saavuttaminen (EI KÄYTETTÄVISSÄ)</div>
        </FlexRow>
        <FlexRow>
          <div className="dashboard-box disabled">HYLLYPAIKAN SIIRTO (EI KÄYTETTÄVISSÄ)</div>
          <div className="dashboard-box" style={{ flexDirection: 'column' }}>
            <Link icon="icon-reload" onClick={() => window.location.reload()}>
            Lataa uudelleen
            </Link>
            <span style={{ fontSize: 14, textTransform: 'none' }}>v0.9900</span>
          </div>
        </FlexRow>
      </Page>
    );
  }
}
