import { Button, F7Button, Link, List, ListInput, ListItem, Navbar, NavLeft, Page } from 'framework7-react';
import * as React from 'react';
import EanCoder from './ean_coder';
import KeyCaptureInstance, { KeyCaptureEvents } from './key_capture';
import { createInventoryTransaction, getCurrentUserProductDefaultStock, getProduct } from './repository';
import barcode from './barcode.png';
import { FlexRow } from './common';

export interface Props {
}

export interface State {
  code: string;
  product?: any;
  saldo: string;
  selite: string;
  stock: string;
  type: string;
}

export class InventoryPage extends React.Component<Props, State> {
  state = {
    code: '',
    product: null,
    stock: '',
    saldo: '',
    selite: '',
    type: '0'
  }

  componentDidMount() {
    KeyCaptureInstance.observe(this.onBarcode);
    this.refocus()
    KeyCaptureInstance.reset();
  }

  refocus() {
    const activeElement = (window.document.activeElement as any);
    if (activeElement && activeElement.blur) {
      activeElement.blur()
    }
  }

  componentWillUnmount() {
    KeyCaptureInstance.cancel(this.onBarcode)
  }

  notify(text: string, title = "Viivakoodi skannattu") {
    var notification = this.$f7?.notification.create({
      title,
      text,
      closeTimeout: 3000
    })
    notification?.open()
  }

  private onCode = (code: string) => {
    this.setState({ code }, async () => {
      const item = EanCoder.getItem(code);
      console.log('scanned code', code, 'item', item);
      if (!item) {
        return
      }
      
      try {
        const product = await getProduct(item[0]);
        console.log('product')
        const stock = await getCurrentUserProductDefaultStock(item[0]);
        console.log('has stock', stock)
        console.log('has product', product);
        this.setState({ product, stock })
        this.refocus()
      } catch (e) {
        console.log('stock error', e, (e as any).response);
      }
    })
  }

  private onBarcode = (event?: string, data?: any) => {
    console.log('on barcode', event, data);

    if (event !== KeyCaptureEvents.BARCODE_SCANNED) {
      return
    }

    (window as any).ean = EanCoder;
    this.notify(data);
    this.onCode(data as string);
  }


  private fetch = async () => {

  }

  private onCodeChange = async (evt: any) => {
    console.log('code', evt.currentTarget.value);
    this.setState({ code: evt.currentTarget.value })
  }

  private onSaldoChange = (evt: any) => this.setState({ saldo: evt.currentTarget.value })
  private onSeliteChange = (evt: any) => this.setState({ selite: evt.currentTarget.value })
  private onTypeChange = (evt: any) => this.setState({ type: evt.currentTarget.value })

  private onSubmit = async () => {
    const item = EanCoder.getItem(this.state.code);
    if (this.state.product && item) {
      const product = this.state.product as any;
      // const stock = product['a:ProductStocks']['a:ProductStock']['a:Stock_number'] || '';
      
      const shelf = product['a:ProductStocks']['a:ProductStock']['a:Stock_shelf'] || '';
      await createInventoryTransaction(item[0], this.state.stock, shelf, this.state.saldo, this.state.type, this.state.selite)
      this.notify('Succcessfully updated');
      this.setState({
        code: '',
        product: null,
        stock: '',
        saldo: '',
        selite: '',
        type: '0'
      })
    } else {
      this.notify('Unable to update item')
    }
  }

  private renderShelves = () => {
    const stock = Array.isArray(this.state.product!['a:ProductStocks']['a:ProductStock']) ? 
      this.state.product!['a:ProductStocks']['a:ProductStock'][0] : 
      this.state.product!['a:ProductStocks']['a:ProductStock']; 

      console.log('stock', stock);
    const shelves = Array.isArray(stock['a:Shelves']['a:StockShelf']) ? stock['a:Shelves']['a:StockShelf'] : [ stock['a:Shelves']['a:StockShelf']];
    const filtered = shelves.filter(shelf => shelf['a:Stock_shelf'] !== '');
    console.log('shelves', shelves, "filtered", filtered);
    const shelf = filtered[0];

    console.log('shelf', shelf);
    // return stocks.map((shelf: any, index: number) => {
    return <List className="item-details" key={`shelf`}>
      <ListItem 
        title="Hylly"
        after={shelf['a:Stock_shelf']}
      />

      <ListItem
        title="Saldo"
        after={shelf['a:Stock_instock']}
      />
    </List>
    // })
  }

  private onSaldoFocus = () => {
    console.log('on saldo focus');
    setTimeout(() => {
      this.$f7.input.scrollIntoView('#inventory-btn')
    }, 1000)
  }

  private promptCodeChange = () => this.setState({ product: null }, () => {
    this.refocus();
    this.onCode(this.state.code);
  })

  public render() {
    return (
      <Page onPageReinit={() => this.fetch()}>
        <Navbar> 
          <NavLeft>
            <Link icon="icon-back" href="/"/>
            <span className="navbar-title">Inventaario</span>
          </NavLeft>
        </Navbar>

        <FlexRow>
          <List mediaList className="item-details" style={{ flex: 1 }}>
            <ListInput 
              style={{ flex: 1 }}
              inlineLabel={true} 
              type="text" 
              label="EAN Koodi" 
              inputStyle={{ textAlign: 'right' }} 
              value={this.state.code}
              onBlur={this.promptCodeChange}
              onChange={this.onCodeChange}
            />
          </List>
          <Button
            style={{ margin: 12 }}
            iconF7="search"
            outline
            onClick={this.promptCodeChange}
          />
        </FlexRow>

        { this.state.product === null && <div className="product-scan-placeholder">
          <img alt="barcode" src={barcode} />
          <span>Lue viivakoodi</span>
        </div> }

        { this.state.product !== null && <List mediaList style={{ flex: 3 }} className="item-details">
          <ListItem title="Nimike" after={this.state.product!['a:Product_description']}/>
          <ListItem title="Tuotekoodi" after={this.state.product!['a:Product_code']}/>
          <ListItem title="Toimittajan koodi" after={this.state.product!['a:ProductSupplierList'] ? this.state.product!['a:ProductSupplierList']['a:ProductSupplier']['a:Supplier_code'] : ''}/>
          {/* <ListItem title="Oletusmäärä" after={this.state.product!['a:ProductUnits'][0]['a:ProductUnit'][0]['a:Product_unit_default_amount'][0]}/> */}
        </List> }
        { this.state.product !== null && this.renderShelves() }

        <List className="item-details" style={{ flex: 1 }}>
          <ListInput
            style={{ flex: 1 }}
            inlineLabel={true}
            type="select"
            label="Laji"
            inputStyle={{ textAlign: 'right', backgroundColor: '#f0f0f0' }}
            value={this.state.type}
            onChange={this.onTypeChange}
          >
            <option value="0">Suorakirjaukset</option>
            <option value="1">Myynti/osto</option>
            <option value="2">Laina/koekäyttö</option>
            <option value="3">Oma äyttö</option>
            <option value="4">Romutus</option>
          </ListInput>

          <ListInput
            style={{ flex: 1 }}
            inlineLabel={true}
            type="text"
            label="Uusi saldo"
            inputStyle={{ textAlign: 'right', backgroundColor: '#f0f0f0' }}
            value={this.state.saldo}
            onChange={this.onSaldoChange}
            onFocus={this.onSaldoFocus}
          />

          <ListInput
            style={{ flex: 1 }}
            inlineLabel={true}
            type="text"
            label="Tapahtuman Selite"
            inputStyle={{ textAlign: 'right', backgroundColor: '#f0f0f0' }}
            value={this.state.selite}
            onChange={this.onSeliteChange}
          />
        </List>


          <F7Button 
            id="inventory-btn"
            fill
            text="Inventoi"
            style={{
              marginTop: 12,
              marginLeft: 12,
              marginRight: 12
            }}
            onClick={this.onSubmit}
          />
      </Page>
    );
  }
}
