import * as React from 'react';
import { Page, List, ListItem, Navbar, NavRight, Button, NavLeft, Link, Popover, Tabs, Tab, Toolbar, Icon } from 'framework7-react';
import { getOrders, getSellers, lockItem } from './repository' 
import Axios from 'axios';
import { formatDate } from '@patterns/core';
import { FlexRow } from './common';
import { live, LiveEvents } from './live.service';
import { session } from './session';
import { orderManager } from './order_manager';

export interface Props {}

export interface State {
  codes: ProductCodes;
  collected: any[];
  documentMap: { [id: string]: string }
  isLoading: boolean;
  orders: any[];
  showSellers: boolean;
  seller?: any;
  sellers: any[];
}

export type ProductCodes = { [id:string]: string }

export default class Orders extends React.Component<Props, State> {
  state = {
    isLoading: false,
    codes: {} as ProductCodes,
    collected: [],
    documentMap: {} as { [id: string]: string },
    orders: [],
    sellers: [],
    showSellers: false
  } as State

  componentDidMount() {
    this.fetchSellers();
    live.observe(LiveEvents.Update, this.onLiveUpdate);
    orderManager.observe(this.onCollectedChanged);
  }

  componentWillUnmount() {
    live.cancel(LiveEvents.Update, this.onLiveUpdate)
  }

  private onCollectedChanged = () => {
    this.setState({
      collected: orderManager.getCollected()
    })
  }

  private onLiveUpdate = async (data: { [id: string]: string }) => {
    this.setState({ documentMap: data })
  }

  async fetchSellers() {
    const sellers = await getSellers();
    const documentMapRes = await Axios.get('/api/sales_orders/locks');

    this.setState({ documentMap: documentMapRes.data, sellers }, () => {
      this.fetch();
    })
  }
  
  async fetch() {
    this.$f7?.progressbar.show();
    const orders = await getOrders() as any[];
    const codes = orders.map(o => o.Sales_order_id).join(',');
    const orderStatesRes = await Axios.get(`/api/sales_orders/match?codes=${codes}`);
    orderStatesRes.data.orders.forEach((orderState: any) => {
      const order = orders.find(o => o.Sales_order_id === orderState.id.toString());
      if (order) {
        order.state = orderState.state;
        order.sellerNumber = orderState.sellerNumber;
        order.updatedAt = orderState.updatedAt;
        order.seller = this.state.sellers.find((seller: any) => parseInt(seller.id) === parseInt(order.sellerNumber))
      }
    });

    const filteredOrders = this.state.seller ? orders.filter(o => o.seller && o.seller.id === this.state.seller.id) : orders;
    // const collectedRes = await Axios.get('/api/sales_orders/collected');

    // const filteredCollected = this.state.seller ? collectedRes.data.collected.filter((c: any) => c['a:Person_seller_number'][0] === this.state.seller.id) : collectedRes.data.collected;
    this.setState({
      collected: orderManager.getCollected(),
      orders: filteredOrders
    });
    this.$f7?.progressbar.hide();
  }

  private setSeller = (_seller: any) => {
    const seller = this.state.seller && this.state.seller.id === _seller.id ? undefined : _seller;
    this.setState({ seller }, () => this.fetch())
  }

  private showSellers =() => this.setState({ showSellers: true })

  private renderItems() {
    return this.state.orders.map((order: any) => <ListItem 
      key={order.Sales_order_number}
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.state.documentMap[order.Sales_order_number]) {
          var notification = (this as any).$f7.notification.create({
            title: 'TILAUS ON LUKITTU',
            closeTimeout: 3000
          });
          notification.open()
        } else {
          lockItem(order.Sales_order_id);
          live.send('update', { document: order.Sales_order_number, session: session.id  })
          if (window.innerWidth <= 770) {
            this.$f7router?.navigate(`/narrow_detail/${order.Sales_order_number}`);
          } else {
            this.$f7router?.navigate(`/detail/${order.Sales_order_number}`);
          }
        }
      }}>
      <span style={{ fontSize: 20, fontWeight: 'bold' }} slot="title">{ order.Sales_order_number }</span>
      <span style={{ fontSize: 16, fontWeight: 'normal' }} slot="subtitle">
        <FlexRow className={this.state.documentMap[order.Sales_order_number] ? 'locked' : ''}>
          <div className="f-1">
            { this.state.documentMap[order.Sales_order_number] && <Icon f7="lock" /> }
            { order.Sales_order_delivery_customer_name1 }
          </div>
          { order.Sales_order_description.length === 0 && <div className="f-1 ta-r">
            { formatDate(order.Sales_order_date) }
          </div>}
        </FlexRow>
        <FlexRow>
          { order.Sales_order_description }
          { order.Sales_order_description.length > 0 && <div className="f-1 ta-r">
            { formatDate(order.Sales_order_date) }
          </div>}
        </FlexRow>
      </span>
    </ListItem>)
  }

  private renderCollected() {
    return this.state.collected.map((order: any, index: number) => <ListItem
      className={index === 0 ? 'first-collected' : ''}
      key={order['a:Sales_order_number']}>
      <span style={{ fontSize: 20, fontWeight: 'bold' }} slot="title">{ order['a:Sales_order_number'][0] }</span>
      <span style={{ fontSize: 16, fontWeight: 'normal' }} slot="subtitle">
        <FlexRow>
          <div className="f-1">
            { order['a:Sales_order_delivery_customer_name1'][0] }
          </div>
          { order['a:Sales_order_description'][0].length === 0 && <div className="f-1 ta-r">
            { formatDate(order['a:Sales_order_date']) }
          </div>}
        </FlexRow>
        <FlexRow>
          { order['a:Sales_order_description'] }
          { order['a:Sales_order_description'][0].length > 0 && <div className="f-1 ta-r">
            { formatDate(order['a:Sales_order_date']) }
          </div>}
        </FlexRow>
      </span>
    </ListItem>)
  }

  public render() {
    return (
      <Page
        onPageReinit={() => this.fetch()}>
        <Navbar> 
          <NavLeft>
            <Link icon="icon-back" href="/"/>
            <span className="navbar-title">Hyllykeräily</span>
          </NavLeft>
          <NavRight>
            { this.state.seller && <div className="m-r-24">{ this.state.seller.name }</div>}
            <Button 
              color="blue"
              onClick={() => {this.showSellers()}}
              iconF7="person" 
              popoverOpen=".popover-menu"
              style={{ fontSize: 16, marginRight: 12, fontWeight: 'bold' }}
            />

            <Button 
              color="blue"
              onClick={() => {this.fetch()}}
              iconF7="arrow_2_circlepath" 
              className="m-l-24"
              style={{ fontSize: 16, marginRight: 12, fontWeight: 'bold' }}
            />
          </NavRight>
        </Navbar>

        <Toolbar tabbar top>
          <Link tabLink="#tab-1" tabLinkActive>KERÄYSLUPA</Link>
          <Link tabLink="#tab-2">KERÄTTY</Link>
        </Toolbar>

        <Tabs animated>
          <Tab id="tab-1" className="page-content" tabActive style={{ paddingTop: 0 }}>
            <List mediaList style={{ marginTop: 0 }}>
              { this.renderItems() }
            </List>
          </Tab>
          <Tab id="tab-2" className="page-content" style={{ paddingTop: 0 }}>
            <List mediaList style={{ marginTop: 0 }}>
              { this.renderCollected() }
            </List>
          </Tab>
        </Tabs>

        <Popover className="popover-menu">
          <List>
            <ListItem title="VALITSE MYYJÄ"/>
            { this.state.sellers.filter((s: any) => s.name.length > 1).map((seller: any, index: number) => <ListItem 
              key={`seller-item-${index}`}
              link="#" 
              onClick={() => this.setSeller(seller)}
              popoverClose
              className={this.state.seller && seller.id === this.state.seller.id ? 'stronger' : 'regular'}
              title={seller.name}/>
            )}
          </List>
        </Popover>
      </Page>
    );
  }
}
