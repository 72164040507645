import axios, { AxiosError, AxiosResponse } from 'axios';
import { parseString } from 'xml2js';
import EanCoder from './ean_coder';
import { session } from './session';
import { getOrdersTemplate, getShelfOrderTemplate, lemonLogin, salesOrderMarkCollected, getSellersTemplate, saveSalesOrder, getCustomerTemplate, keepAliveTemplate, lockItemTemplate, releaseItemTemplate, getProductTemplate, getCurrentUserProductDefaultStockTemplate, createInventoryTransactionTemplate, saveTransactionTemplate } from './templates';

import { XMLParser, XMLBuilder } from 'fast-xml-parser';

const parser = new XMLParser({ 
  numberParseOptions: { leadingZeros: false, hex: false }
});

const builder = new XMLBuilder({}) as any;

const ROOT_URL = '/LemonsoftWebServiceSetup/UserServices.svc';

export type LemonPerson = {

}

export const intercept = () => {
  axios.interceptors.response.use((response: AxiosResponse) => response, async (error: AxiosError) => {
    const errorCodes = [400, 401, 403, 500];
    if (error.response && errorCodes.includes(error.response.status)) {
      console.log('response', error.response)
      await session.refresh();
      if (session.authorized) {
        const newSession = error.config.data.match(/<tem:strSessionId>(.+?)<\/tem:strSessionId>/g) ? 
          `<tem:strSessionId>${session.id}</tem:strSessionId>` :
          `<tem:strSessionID>${session.id}</tem:strSessionID>`;
        const edited = error.config.data
          .replace(/<strSessionId>(.+?)<\/strSessionId>/gi, newSession)
          .replace(/<strSessionID>(.+?)<\/strSessionID>/gi, newSession)
          .replace(/<tem:strSessionId>(.+?)<\/tem:strSessionId>/gi, newSession)
          .replace(/<tem:strSessionID>(.+?)<\/tem:strSessionID>/gi, newSession)
          .replace(/<a:strSessionId>(.+?)<\/a:strSessionId>/gi, newSession)
          .replace(/<a:strSessionID>(.+?)<\/a:strSessionID>/gi, newSession);
        error.config.data = edited;
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(axios.request(error.config))
        }, 1500);
      })
    }

    console.log('interceptor => canceling request with error');
    return Promise.reject(error);
  })
}

const parseXML = (data: string) => new Promise((resolve, reject) => {
  parseString(data, (error, data) => {
    if (error) {
      reject(error)
      return
    }

    resolve(data)
  })
});

export async function getCurrentUserProductDefaultStock(code: string) {
  const content = getCurrentUserProductDefaultStockTemplate(code);
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/IProductService/GetCurrentUserProductDefaultStock',
      'Content-Type': 'text/xml'
    }
  });

  const data = await parseXML(response.data) as any;
  const stock = data["s:Envelope"]['s:Body'][0]['GetCurrentUserProductDefaultStockResponse'][0]['GetCurrentUserProductDefaultStockResult'][0];
  console.log('default stock', stock);
  return stock;
}

export async function createInventoryTransaction(code: string, stock: string | number, shelf: string, amount: string | number, type: string, note: string) {
  const content = createInventoryTransactionTemplate(code, 1); // 1 is the stock id
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/IProductService/CreateInventoryTransaction',
      'Content-Type': 'text/xml'
    }
  });

  const transaction = parser.parse(response.data)['s:Envelope']['s:Body']['CreateInventoryTransactionResponse']['CreateInventoryTransactionResult'];
  transaction['a:Stock_transaction_amount'] = amount;
  transaction['a:Stock_transaction_stock_number'] = stock;
  transaction['a:Stock_transaction_shelf'] = shelf;
  transaction['a:Stock_transaction_description'] = note;
  transaction['a:Stock_transaction_type'] = 3;
  const transactionXML = builder.build({ 'tem:oTransaction': transaction });
  console.log(saveTransactionTemplate(transactionXML));

  const transactionResponse = await axios.post(ROOT_URL, saveTransactionTemplate(transactionXML), {
    headers: {
      'Soapaction': 'http://tempuri.org/IProductService/SaveTransaction',
      'Content-Type': 'text/xml'
    }
  });

  const data = await parseXML(transactionResponse.data) as any;
  const result = data["s:Envelope"]['s:Body'][0]['SaveTransactionResponse'][0]['SaveTransactionResult'][0];
  return result;
}

export async function getCustomer(customerNumber: string) {
  const content = getCustomerTemplate(customerNumber);
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ICustomerService/GetCustomer',
      'Content-Type': 'text/xml'
    }
  });

  const data = await parseXML(response.data) as any;
  const customer = data["s:Envelope"]['s:Body'][0]['GetCustomerResponse'][0]['GetCustomerResult'][0];
  return customer;
}

export async function getProduct(code: string) {
  const content = getProductTemplate(code);
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/IProductService/GetProduct',
      'Content-Type': 'text/xml'
    }
  });

  const data = await parser.parse(response.data) as any;
  const product = data["s:Envelope"]['s:Body']['GetProductResponse']['GetProductResult'];
  console.log('product', product);
  return product;
}

export async function getSellers() {
  const content = getSellersTemplate();
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ILemonsoftService/GetPersonsWithRole',
      'Content-Type': 'text/xml'
    }
  });

  const data = await parseXML(response.data) as any;
  const roughSellers = data["s:Envelope"]['s:Body'][0]['GetPersonsWithRoleResponse'][0]['GetPersonsWithRoleResult'][0]['a:PersonMainData'];
  const sellers = roughSellers.map((item: any) => ({
    id: item['a:Person_number'][0],
    name: item['a:Person_name'][0],
    shortName: item['a:Person_shortname'][0]
  }))
  return sellers;
}

export async function getOrders() {
  const content = getOrdersTemplate();
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ISalesOrderService/GetSalesOrderReadyToCollect',
      'Content-Type': 'text/xml'
    }
  });

  const orders = await new Promise<any[]>((resolve, reject) => {
    parseString(response.data, (error, data) => {
      if (error) {
        reject(error)
        return
      }
      const roughItems = data["s:Envelope"]['s:Body'][0].GetSalesOrderReadyToCollectResponse[0].GetSalesOrderReadyToCollectResult[0]['a:SalesOrderMainData'];
      const items = roughItems.map((item: any) => ({
        Codelist_driver: item['a:Codelist_driver'][0],
        Sales_order_date: item['a:Sales_order_date'][0],
        Sales_order_delivery_customer_address3: item['a:Sales_order_delivery_customer_address3'][0],
        Sales_order_delivery_customer_name1: item['a:Sales_order_delivery_customer_name1'][0],
        Sales_order_delivery_customer_number: item['a:Sales_order_delivery_customer_number'][0],
        Sales_order_delivery_date: item['a:Sales_order_delivery_date'][0],
        Sales_order_delivery_text: item['a:Sales_order_delivery_text'][0],  
        Sales_order_description: item['a:Sales_order_description'][0],
        Sales_order_id: item['a:Sales_order_id'][0],
        Sales_order_number: item['a:Sales_order_number'][0],
        Sales_order_ordermark: item['a:Sales_order_ordermark'][0],
        Sales_order_state: item['a:Sales_order_state'][0],
        Sales_order_totalsum: item['a:Sales_order_totalsum'][0]
      }));
  
      resolve(items)
    })
  })

  return orders;
}

export async function getShelfOrder(salesOrderId: string) {
  const content = getShelfOrderTemplate(salesOrderId);
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ISalesOrderService/GetSalesOrderShelfOrder',
      'Content-Type': 'text/xml'
    }
  });

  return new Promise((resolve, reject) => {
    parseString(response.data, (error, data) => {
      if (error) {
        reject(error)
        return
      }

      const order = data['s:Envelope']['s:Body'][0].GetSalesOrderShelfOrderResponse[0].GetSalesOrderShelfOrderResult[0];
      const items = order['a:OrderRows'][0]['a:SalesOrderRow'];
      order.items = items.map((item: any) => ({
        code: item['a:Row_productcode'][0],
        eancode: EanCoder.getEan(item['a:Row_productcode'][0]),
        description: item['a:Row_productdescription'][0],
        unit: item['a:Row_stock_unit'][0],
        defaultShelf: item['a:DefaultShelf'][0],
        defaultShelfCollectOrder: item['a:DefaultShelfCollectOrder'][0],
        rowAmount: item['a:ToBeDelivered'][0],
        collected: 0
      }));
      return resolve(order);
    });
  });
}

export async function saveCollectOrder(order: any) {
  const content = fixChars(salesOrderMarkCollected(order));
  await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ISalesOrderService/SalesOrderMarkCollected',
      'Content-Type': 'text/xml'
    }
  });
}

const fixChars = (str: string) => {
  return str.replace(new RegExp( "\\n", "g" ), "")
    .replace(/&#x1F;/g, ' ')
    .replace(/>\s*/g, '>')
    .replace(/\s*</g, '<')
    .replace(/\n/, '')
    .replace(/å/g, 'a§')
    .replace(/ä/g, 'a')
    .replace(/Ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/Ö/g, 'o')
    .replace(/>> /g, '>')
    .replace(/>>/g, '>')
    .replace(/> /g, '>')
    .replace(/&/g, '')
    .replace(/Row_productdescription2>< /g, 'Row_productdescription2>')
    .replace(/Row_productdescription2> /g, 'Row_productdescription2>')
}

export async function saveOrder(order: any) {
  const content = fixChars(saveSalesOrder(order));
  await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ISalesOrderService/SaveSalesOrder',
      'Content-Type': 'text/xml'
    }
  });
}

export async function keepAlive() {
  const content = keepAliveTemplate();
  await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/IAuthenticationService/IsAlive',
      'Content-Type': 'text/xml'
    }
  });
}

export async function lockItem(order_id: string) {
  const content = lockItemTemplate(order_id);
  await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ILemonsoftService/LockSmith_LockItem',
      'Content-Type': 'text/xml'
    }
  });
}

export async function releaseItem(order_id: string) {
  const content = releaseItemTemplate(order_id);
  await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/ILemonsoftService/LockSmith_ReleaseItem',
      'Content-Type': 'text/xml'
    }
  });
}

export async function login(username: string, password: string): Promise<string> {
  const content = lemonLogin(username, password);
  const response = await axios.post(ROOT_URL, content, {
    headers: {
      'Soapaction': 'http://tempuri.org/IAuthenticationService/LogIn',
      'Content-Type': 'text/xml'
    }
  });
  
  const sessionId = await new Promise<string>((resolve, reject) => {
    parseString(response.data, (error, data) => {
      if (error) {
        reject(error)
        return
      }

      // resultCode should be 'OK'
      const resultCode = data['s:Envelope']['s:Body'][0]['LogInResponse'][0]['LogInResult'][0]['a:ResultCode'][0];

      if (resultCode !== 'OK') {
        reject(new Error('Invalid Credentials'))
        return
      }

      const sessionId = data['s:Envelope']['s:Body'][0]['LogInResponse'][0]['LogInResult'][0]['a:SessioID'][0];
      resolve(sessionId)
    })
  })

  return sessionId;
}