import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'framework7-react';
import { FlexRow, FlexColumn } from './common';

const Display = styled.div`
  background-color: #d3d3d3;
  margin: 2px 4px;
  font-size: 22px;
  font-weight: bold;
  padding: 6px 12px;
  text-align: right;
  flex: 1;
`

export interface Props {
  disabled: boolean
  value: number
  onChange: (number: number) => void
  onCollect: () => void
}

const btnStyle = { width: 'calc((100% - 2px) / 3)', margin: 1, fontSize: 18, fontWeight: 'bold', borderRadius: 0 } as React.CSSProperties

export class KeyPad extends React.Component<Props> {
  private onNumber = (number: number) => {
    const newNumber = `${parseInt(this.props.value.toString())}${number}`;
    this.props.onChange(parseInt(newNumber));
  }

  private onDel = () => {
    const str = this.props.value.toString();
    if (str.length <= 1) {
      this.props.onChange(0)
    } else {
      const newNumber = str.substring(0, str.length - 1)
      this.props.onChange(parseInt(newNumber))
    }
  }

  private button = (num: number) => <Button
    disabled={this.props.disabled} 
    raised
    large
    fill
    text={`${num}`}
    style={btnStyle}
    onClick={() => this.onNumber(num)}
  />

  public render() {
    return (
      <FlexColumn style={{ marginLeft: 12,marginRight: 12, marginBottom: 12 }}>
        <FlexRow>
          <Display>{ this.props.value }</Display>
        </FlexRow>
        <FlexRow>
          { this.button(7) }
          { this.button(8) }
          { this.button(9) }
        </FlexRow>
        <FlexRow>
          { this.button(4)}
          { this.button(5)}
          { this.button(6)}
        </FlexRow>
        <FlexRow>
          { this.button(1)}
          { this.button(2)}
          { this.button(3)}
        </FlexRow>
        <FlexRow style={{ margin: '0' }}>
          <Button disabled={this.props.disabled} raised large fill text="0" style={{...btnStyle,
            width: 'calc((100% - 2px) / 3 * 2)',
            margin: '1px 1px 1px 1px'
          }} onClick={() => this.onNumber(0)}/>
          <Button color="red" disabled={this.props.disabled} raised large fill text="DEL" style={{...btnStyle,
            width: 'calc((100% - 2px) / 3)',
            margin: '1px 1px 1px 1px'
          }} onClick={() => this.onDel()}/>
        </FlexRow>
        <FlexRow>
          <Button color="green" disabled={this.props.disabled} raised large fill text="KERÄÄ" style={{...btnStyle, flex: 1 }} onClick={() => this.props.onCollect()}/>
        </FlexRow>
      </FlexColumn>
    );
  }
}
