import { Button, Link, List, ListInput, ListItem, Navbar, NavLeft, Page } from 'framework7-react';
import * as React from 'react';
import EanCoder from './ean_coder';
import KeyCaptureInstance, { KeyCaptureEvents } from './key_capture';
import { getProduct } from './repository';
import barcode from './barcode.png';
import { FlexRow } from './common';

export interface Props {
}

export interface State {
  code: string
  product: any
}

export class ProductPage extends React.Component<Props, State> {
  state = {
    code: '',
    product: null
  }

  componentDidMount() {
    KeyCaptureInstance.observe(this.onBarcode);
    this.refocus()
    KeyCaptureInstance.reset();
  }

  refocus() {
    const activeElement = (window.document.activeElement as any);
    if (activeElement && activeElement.blur) {
      activeElement.blur()
    }
  }

  componentWillUnmount() {
    KeyCaptureInstance.cancel(this.onBarcode)
  }

  notify(text: string, title = "Viivakoodi skannattu") {
    var notification = this.$f7?.notification.create({
      title,
      text,
      closeTimeout: 3000
    })
    notification?.open()
  }

  private onCode = (code: string) => {
    this.setState({ code }, async () => {
      const item = EanCoder.getItem(code);
      console.log('scanned code', code, 'item', item);
      if (!item) {
        return
      }
      
      const product = await getProduct(item[0]);
      console.log('has product', product);
      this.setState({ product })
      this.refocus()
    })
  }

  private onBarcode = (event?: string, data?: any) => {
    console.log('on barcode', event, data);

    if (event !== KeyCaptureEvents.BARCODE_SCANNED) {
      return
    }

    (window as any).ean = EanCoder;
    this.notify(data);
    this.onCode(data as string);
  }


  private fetch = async () => {

  }

  private onCodeChange = async (evt: any) => this.setState({ code: evt.currentTarget.value })

  private renderShelves = () => {
    const stocks = Array.isArray(this.state.product!['a:ProductStocks']['a:ProductStock']) ? 
      this.state.product!['a:ProductStocks']['a:ProductStock'] : 
      [ this.state.product!['a:ProductStocks']['a:ProductStock'] ];

    return stocks.map((shelf: any, index: number) => {
      return <List className="item-details" key={`shelf-${index}`}>
        <ListItem
          title="Varastosaldo"
          after={shelf!['a:Stock_instock']}
        />
        <ListItem 
          title="Tulossa"
          after={shelf['a:In_Saldo']}
        />
        <ListItem 
          title="Menossa"
          after={shelf['a:Out_Saldo']}
        />

        <ListItem 
          title="Hylly"
          after={shelf['a:Stock_shelf']}
        />
        
        <ListItem 
          title="Vapaa saldo"
          after={shelf['a:Free_Saldo']}
        />        
      </List>
    })
  }

  private promptCodeChange = () => this.setState({ product: null }, () => {
    this.refocus();
    this.onCode(this.state.code);
  })

  public render() {
    return (
      <Page onPageReinit={() => this.fetch()}>
        <Navbar> 
          <NavLeft>
            <Link icon="icon-back" href="/"/>
            <span className="navbar-title">Tuotehaku</span>
          </NavLeft>
        </Navbar>

        <FlexRow>
          <List mediaList className="item-details" style={{ flex: 1 }}>
            <ListInput 
              style={{ flex: 1 }}
              inlineLabel={true} 
              type="text" 
              label="EAN Koodi" 
              inputStyle={{ textAlign: 'right' }} 
              value={this.state.code}
              onBlur={this.promptCodeChange}
              onChange={this.onCodeChange}
              
            />
          </List>
          <Button
            style={{ margin: 12 }}
            iconF7="search"
            outline
            onClick={this.promptCodeChange}
          />
        </FlexRow>

        { this.state.product === null && <div className="product-scan-placeholder">
          <img alt="barcode" src={barcode} />
          <span>Lue viivakoodi</span>
        </div> }
        { this.state.product !== null && <List mediaList style={{ flex: 3 }} className="item-details">
          <ListItem title="Nimike" after={this.state.product!['a:Product_description']}/>
          <ListItem title="Tuotekoodi" after={this.state.product!['a:Product_code']}/>
          <ListItem title="Toimittajan koodi" after={this.state.product!['a:ProductSupplierList'] ? this.state.product!['a:ProductSupplierList']['a:ProductSupplier']['a:Supplier_code'] : ''}/>
          <ListItem title="Oletusmäärä" after={this.state.product!['a:ProductUnits']['a:ProductUnit']['a:Product_unit_default_amount']}/>
          <ListItem title="Tukkuhinta" after={this.state.product!['a:Product_price'] + ' €'}/>
        </List> }
        { this.state.product !== null && this.renderShelves() }
      </Page>
    );
  }
}
