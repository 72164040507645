import Axios from "axios";

export type EanCodes = { [id:string]: string }

const Minutes = [5, 20, 35, 50];

export class EanCoder {
  codes = [] as string[][]

  codeMap = new Map() as Map<string, string[]>
  eancodeMap = new Map() as Map<string, string[]>
  unitCodeMap = new Map() as Map<string, string[]>
  supplierCodeMap = new Map() as Map<string, string[]>

  reversed ={} as EanCodes
  handle: any;

  constructor() {
    this.handle = setInterval(() => {
      const now = new Date();
      if (Minutes.includes(now.getMinutes())) {
        console.log(now, 'reloading ean codes & stocks...');
        this.fetch()
      }
    }, 1000 * 60)
  }
  
  fetch = async () => {
    const response = await Axios.get('/api/products/codes');
    this.codes = response.data;
    
    this.codeMap = new Map() as Map<string, string[]>
    this.eancodeMap = new Map() as Map<string, string[]>
    this.unitCodeMap = new Map() as Map<string, string[]>
    this.supplierCodeMap = new Map() as Map<string, string[]>

    this.codes.forEach(codes => {
      this.codeMap.set(codes[0], codes);
      this.eancodeMap.set(codes[1], codes);
      this.unitCodeMap.set(codes[2], codes);
      this.supplierCodeMap.set(codes[3], codes);
    })
  }

  findItem = (code: string) => {
    let item = this.codeMap.get(code);
    if (item) {
      return item;
    }

    item = this.eancodeMap.get(code);
    if (item) {
      return item;
    }

    item = this.unitCodeMap.get(code);
    if (item) {
      return item;
    }

    item = this.supplierCodeMap.get(code);
    if (item) {
      return item;
    }

    return null
  }

  getItem = (_code: string) => {
    let code = _code;
    let item = this.findItem(code);
    if (item) {
      return item
    }

    // try prefixes
    code = `0${_code}`;
    item = this.findItem(code);
    if (item) {
      return item
    }

    code = `00${_code}`;
    item = this.findItem(code);
    if (item) {
      return item
    }

    code = `000${_code}`;
    item = this.findItem(code);
    if (item) {
      return item
    }
  }

  get = (_code: string) => {
    const item = this.getItem(_code);
    if (item) {
      return item[0]
    } else {
      return null
    }
  }

  getEan = (code: string) => {
    const item = this.getItem(code);
    if (item) {
      return item[1]
    }

    return null
  }

  getFreeSaldo = (code: string) => {
    const item = this.getItem(code);
    if (item) {
      return item[4];
    }

    return null
  }
}

const instance = new EanCoder();
export default instance;