import { Eventable } from './eventable'

export enum KeyCaptureEvents {
  BARCODE_SCANNED = 'keycapture:barcode:scanned'
}

export class KeyCapture extends Eventable {
  query: string = ''

  capture = (evt: any) => {
    const key = evt.key as string;

    if (key === 'Enter' && this.query.length > 0) {
      this.notify(KeyCaptureEvents.BARCODE_SCANNED, this.query)
      this.query = ''
      return
    }

    if (key.length === 1 && key.match(/[a-z0-9]/)) {
      this.query += evt.key
      return
    }
  }

  reset = () => {
    this.query = ''
  }
};

const KeyCaptureInstance = new KeyCapture();
export default KeyCaptureInstance
