import { Eventable } from "./eventable";
import { live } from "./live.service";
import { keepAlive, login } from "./repository";

const KEEPALIVE_INTERVAL = 1000 * 60 * 1 /// 5 mins

export enum SessionEvents {
  Changed = 'changed'
}

export class Session extends Eventable {
  id: string = '';
  user: string = '';
  authorized: boolean = false;
  keepAliveHandle: number = 0;

  logout() {
    clearInterval(this.keepAliveHandle);
    this.reset();
    this.notify(SessionEvents.Changed);
    live.disconnect();
  }

  async refresh() {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    if (username && password) {
      await this.login(username, password)
    }
  }

  reset() {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('user');
    this.user = '';
    this.id = '';
    this.authorized = false;
    this.stopKeepAlive();
  }

  restore() {
    const storedSession = localStorage.getItem('sessionId');
    const storedUser = localStorage.getItem('user');
    if (storedSession && storedUser) {
      this.id = storedSession;
      this.user = storedUser
      this.authorized = true;
      this.startKeepAlive();
      this.notify(SessionEvents.Changed)
    }
  }

  async keepAlive() {
    await keepAlive()
  }

  startKeepAlive() {
    this.keepAliveHandle = setInterval(this.keepAlive, KEEPALIVE_INTERVAL) as any
  }

  stopKeepAlive() {
    clearInterval(this.keepAliveHandle)
  }

  save() {
    localStorage.setItem('sessionId', this.id);
    localStorage.setItem('user', this.user);
  }

  async login(username: string, password: string) {
    const progressBar = (window as any).progressBar;
    if (progressBar) {
      progressBar.show()
    }
    this.reset();
    const sessionId = await login(username, password);
    if (sessionId) {
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
      this.id = sessionId;
      this.user = username;
      this.authorized = true;
      this.save();
      this.startKeepAlive();
    }
    if (progressBar) {
      progressBar.hide();
    }
    this.notify(SessionEvents.Changed)
  }
}

export const session = new Session();
