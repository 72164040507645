import * as React from 'react';
import { Page, List, ListButton, ListInput, LoginScreenTitle } from "framework7-react";
import { session } from './session';
import logo from './logo-black.png';

export interface Props {}
export interface State {
  error: boolean;
  username: string;
  password: string;
}

export default class App extends React.Component<Props, State> {
  state = {
    error: false,
    username: '',
    password: ''
  }
  
  signIn = async () => {
    try {
      await session.login(this.state.username, this.state.password);
    } catch (e) {
      var notification = (this as any).$f7.notification.create({
        title: 'Login Failure',
        text: 'Invalid Credentials',
        closeTimeout: 3000
      });
      notification.open()
    }

    // const self = this;
    // const app = self.$f7;
    // const router = self.$f7router;
    // app?.dialog.alert(`Username: ${self.state.username}<br>Password: ${self.state.password}`, () => {
    //   router?.back();
    // });
  }
  
  render() {
    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <LoginScreenTitle>
          <img alt="logo" src={logo} style={{ maxHeight: 34, marginLeft: 24 }}/>
        </LoginScreenTitle>
        <List form>
          <ListInput
            label="Käyttäjänimi"
            type="text"
            placeholder="Käyttäjänimesi"
            value={this.state.username}
            onInput={(e) => {
              this.setState({ username: e.target.value});
            }}
          />
          <ListInput
            label="Salasana"
            type="password"
            placeholder="Salasanasi"
            value={this.state.password}
            onInput={(e) => {
              this.setState({ password: e.target.value});
            }}
          />
        </List>
        <List>
          <ListButton onClick={this.signIn.bind(this)}>Kirjaudu sisään</ListButton>
          { this.state.error && <div className="login-error">
            </div>}
        </List>
      </Page>
    )
  }
}
